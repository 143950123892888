import { useEffect, useState } from 'react';
import './App.css';
import { UserData } from './commons/types';
import Splash from './components/splash/Splash';
import { loadAuthorization } from 'services/authorization';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import NotFoundPage from 'routes/NotFoundPage';
import { routes } from 'constants/routes';
import { AuthContext } from 'Contexts';
import { getUserProfile } from 'services/user-profile';

const router = createBrowserRouter(routes.map(r => ({
    path: r.path,
    element: r.component,
    errorElement: <NotFoundPage />
})));

function App() {

    const [user, setUser] = useState<UserData | null>(null);

    useEffect(() => {
        loadAuthorization()
            .then(() => getUserProfile())
            .then(userData => setUser(userData.user))
            .catch(e => {
                console.error(e);
                // window.location.replace("/unauthorized");
            });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <>
        <AuthContext.Provider value={user}>
            <RouterProvider router={router} />
            {!user && <Splash />}
        </AuthContext.Provider>
    </>;
}

export default App;
