import { useRouteError } from "react-router-dom";


export default function NotFoundPage() {
    const error = useRouteError() as any;
    console.error(error);
    return (
        <div className="not-found-page">
            <h1>Page not found</h1>
            <p><i>{error.statusText || error.message}</i></p>
        </div>
    );
}