import Navigation from "components/Navigation";
import UserInfo from "components/UserInfo";
import { ReactNode } from "react";

export const NavigationPageTemplate: React.FunctionComponent<{ children: ReactNode }> = ({ children }) => {
    return (
        <div className="app">
            <header className="app-header">
                <div className="app-header-container">
                    <img alt="Logo" src="/sparkd_logo-small-res.png"></img>
                    <Navigation />
                    <UserInfo />
                </div>
            </header>
            <div className="app-body">
                {children}
            </div>
        </div>
    );
}