import './ProgressBar.css';

interface ProgressBarProps {
    currentProgress: number;
    totalProgress: number;
}

const ProgressBar: React.FunctionComponent<ProgressBarProps> = ({ currentProgress, totalProgress }) => {
    return (
        <div className="progress-bar-container">
            <div className="progress" role="progressbar">
                <div className="progress-bar progress-bar-striped progress-bar-animated" style={{ width: "100%" }}>
                    Loading the site components...
                </div>
            </div>
        </div>
    );
}

export default ProgressBar;