import { AuthContext } from "Contexts";
import { useContext } from "react";
import { FaUser } from "react-icons/fa6";

import './UserInfo.css';

export default function UserInfo() {
    const authContext = useContext(AuthContext);
    return (
        <div className="user-info">
            <div className="user-avatar">
                <FaUser />
            </div>
            <div className="user-name">{authContext?.name}</div>
        </div>
    );
}