

export default function UnauthorizedPage() {
    return (
        <>
            <h2>Unauthorized</h2>
            <p>You don't have permission to access this application.</p>
            <p>Please contact an administrator if you need permission.</p>
        </>
    );
}