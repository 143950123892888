import { AuthContext } from "Contexts"
import RetoolWrapper from "components/RetoolWrapper"
import { useContext } from "react"
import { Navigate } from "react-router-dom"
import { NavigationPageTemplate } from "templates/NavigationPageTemplate"

export const RetoolPage: React.FunctionComponent<{ retoolAppId: string }> = ({ retoolAppId }) => {
    const user = useContext(AuthContext);
    if (!user) {
        return <Navigate to="/" replace={true} />
    }
    return (
        <NavigationPageTemplate>
            <RetoolWrapper retoolAppId={retoolAppId} />
        </NavigationPageTemplate>
    );
}