import { routes } from "constants/routes";
import './Navigation.css';
import { NavLink } from "react-router-dom";
interface ClassNameFn {
    (flags: { isActive: boolean, isPending: boolean }): string;
}

export default function Navigation() {

    const linkClasses = ["navigation-link"];
    const getLinkClassName: ClassNameFn = ({ isActive, isPending }) => {
        if (isActive) {
            return [...linkClasses, "active"].join(" ");
        }
        return linkClasses.join(" ");
    }

    return (
        <nav className="app-navigation">
            {routes.filter(r => r.navigationEnabled).map((route, idx) => <NavLink key={idx} to={route.path} className={getLinkClassName}>{route.name}</NavLink>)}
        </nav>
    );
};