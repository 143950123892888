import SplashBackdrop from "./SplashBackdrop";
import ProgressBar from "components/progress-bar/ProgressBar";
import "./Splash.css";

export default function Splash() {
    return (
        <SplashBackdrop>
            <div className="splash">
                <div className="splash-logo">
                    <img alt="logo" src="/logo_mid_res.png" />
                </div>
                <div className="splash-status">
                    <ProgressBar currentProgress={10} totalProgress={100} />
                </div>
            </div>
        </SplashBackdrop>
    );
}