import httpRequest from "commons/request";
import { useEffect, useState } from "react";
import Retool from 'react-retool';
import { useNavigate } from "react-router-dom";

const API_HOST = process.env.REACT_APP_SPARK_API_HOST;

const RetoolWrapper: React.FunctionComponent<{ retoolAppId: string }> = ({ retoolAppId }) => {
    const [retoolEmbedUrl, setRetoolEmbedUrl] = useState("");
    const navigation = useNavigate();

    useEffect(() => {
        if (retoolAppId) {
            const URL = `${API_HOST}/api/admin/retool-app-url/${retoolAppId}`;
            httpRequest(URL, { method: "GET" })
                .then((resp) => setRetoolEmbedUrl(resp.embedUrl))
                .catch(e => {
                    console.error(e);
                    navigation("/unauthorized");
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [retoolAppId]);

    if (retoolEmbedUrl) {
        return (
            <Retool url={retoolEmbedUrl} />
        )
    }
    return (<></>);
};

export default RetoolWrapper;