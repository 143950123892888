import { ReactNode } from "react";

type Props = {
    children: ReactNode
}

export default function SplashBackdrop({ children }: Props) {
    return (
        <div className="splash-backdrop">
            <div className="splash-container">
                {children}
            </div>
        </div>);
}