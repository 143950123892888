import httpRequest from "commons/request";

export interface Authorization {
    authorized: boolean;
    expiresIn: number;
    accessToken?: string;
    refreshToken?: string;
}

const authorization = {
    authorized: !!process.env.REACT_APP_ACCESS_TOKEN,
    expiresIn: 0,
    accessToken: process.env.REACT_APP_ACCESS_TOKEN,
    refreshToken: process.env.REACT_APP_REFRESH_TOKEN
};

export async function loadAuthorization(): Promise<Authorization> {
    if (process.env.NODE_ENV === "production") {
        const URL = `${process.env.REACT_APP_SPARK_API_HOST}/api/jwt-auth`;
        const response = await httpRequest(URL, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            noAuthHeaders: true,
            credentials: "include",
            body: {
                "grant_type": "cloudflare_grant",
                "client_id": process.env.REACT_APP_SPARK_CLIENT_ID,
                "client_secret": process.env.REACT_APP_SPARK_CLIENT_SECRET,
                "scope": ""
            }
        });
        authorization.authorized = true;
        authorization.accessToken = response.access_token;
        authorization.refreshToken = response.refresh_token;
        authorization.expiresIn = response.expires_in;
    }
    return { ...authorization };
}

export function getAuthorization(): Authorization {
    return { ...authorization };
}