import { getAuthorization } from "services/authorization";
import { HttpStatusError } from "./errors/HttpError";

export interface HttpRequestOptions<B = any, H = any> {
    method: string;
    credentials?: "include";
    body?: B;
    headers?: H;
    noAuthHeaders?: boolean;
}

export default async function httpRequest<R = any, B = any, H = any>(url: string, options: HttpRequestOptions<B, H>): Promise<R> {

    const authorization = getAuthorization();
    const authentication: HeadersInit = !options.noAuthHeaders ? {
        "Authorization": `Bearer ${authorization.accessToken}`
    } : {};
    const response = await fetch(url, {
        method: options.method,
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            ...authentication,
            ...options?.headers
        },
        credentials: options.credentials,
        body: JSON.stringify(options?.body)
    });
    if (response.ok) {
        const json = await response.json();
        return json as R;
    }
    throw new HttpStatusError(response.status, await response.json());
}